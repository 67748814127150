<template>
<div>
   <ckeditor :editor="editor" v-model="editorData" :config="editorConfig" @ready="onReady"></ckeditor>
</div>
</template>

<script>
import Gen from '@/libs/Gen'
import Vue from 'vue'
import CKEditor from '@ckeditor/ckeditor5-vue2'

Vue.use(CKEditor)

import ClassicEditorBase from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import UploadAdapterPlugin from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
import AutoformatPlugin from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline';
import StrikePlugin from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import BlockQuotePlugin from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading';
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image';
import ImageCaptionPlugin from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStylePlugin from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbarPlugin from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ListPlugin from '@ckeditor/ckeditor5-list/src/list';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import IndentPlugin from '@ckeditor/ckeditor5-indent/src/indent';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import TablePlugin from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting'
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter'
// import GeneralHtmlSupport from '@ckeditor/ckeditor5-html-support/src/generalhtmlsupport'

export default{
  props:{
    value:[String,null],
  },
  data(){
    return {
      editorData:this.value,
      editor: ClassicEditorBase,
      editorConfig: {
        plugins: [
          SimpleUploadAdapter,
          EssentialsPlugin,
          UploadAdapterPlugin,
          AutoformatPlugin,
          BoldPlugin,
          ItalicPlugin,
          BlockQuotePlugin,
          HeadingPlugin,
          ImagePlugin,
          ImageCaptionPlugin,
          ImageStylePlugin,
          ImageToolbarPlugin,
          ImageUploadPlugin,
          LinkPlugin,
          ListPlugin,
          ParagraphPlugin,
          Alignment,
          IndentPlugin,
          MediaEmbed,
          TablePlugin,
          TableToolbar,
          UnderlinePlugin,
          StrikePlugin,
          SourceEditing,
          // GeneralHtmlSupport
        ],

        toolbar: {
          items: [
            'sourceEditing',
            '|',
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'link',
            'bulletedList',
            'numberedList',
            'uploadImage',
            'blockQuote',
            '|', 
            'alignment',
            '|', 
            'insertTable','mediaEmbed',
            '|', 
            'outdent', 'indent',
            '|',
            'undo',
            'redo'
          ]
        },
        alignment: {
          options: [ 'left', 'right', 'justify','center' ]
        },
        table: {
          contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
        },
        link: {
          defaultProtocol: 'http://',
          decorators: {
              openInNewTab: {
                  mode: 'manual',
                  label: 'Open in a new tab',
                  defaultValue: true,			// This option will be selected by default.
                  attributes: {
                      target: '_blank',
                      rel: 'noopener noreferrer'
                  }
              }
          }
        },
        image: {
          toolbar: [
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            '|',
            'toggleImageCaption',
            'imageTextAlternative'
          ]
        },
        language: 'en',

        simpleUpload: {
          uploadUrl: process.env.VUE_APP_API_URL + "/api/app/ajax/upload_editor?pageType=editor",
          //withCredentials: true,
          headers:Gen.apiHeader()
        },
        htmlSupport: {
            allow: [
                {
                    name: /.*/,
                    attributes: true,
                    classes: true,
                    styles: true
                }
            ]
        }
      },
      htmlSupport: {
          allow: [
              {
                  name: /.*/,
                  attributes: true,
                  classes: true,
                  styles: true
              }
          ]
      },
    }
  },
  methods: {
    onReady(editor){
      editor.editing.view.change(writer => {
        writer.setStyle( 'height', '500px', editor.editing.view.document.getRoot() );
        writer.setStyle( 'min-height', '500px', editor.editing.view.document.getRoot() );
      });
    }
  },
  watch:{
    value(v){
      this.$set(this,'editorData',v)
    },
    editorData(v){
      this.$emit('update:value', v)
    }
  }
}
</script>
